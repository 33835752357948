export default function usePrint() {
    const state = {
        printLocal: function () {
            window.print();
        },
        printRemoteUsingIframe(url) {
            const iframe = document.createElement('iframe');
            iframe.style.display = 'none';
            iframe.src = url;
            document.body.appendChild(iframe);
            iframe.onload = () => {
                iframe.contentWindow.print();
                setTimeout(() => {
                    document.body.removeChild(iframe);
                }, 1);
            };
        },
        printRemote: function (url, target = null) {
            if (!target) {
                return state.printRemoteUsingIframe(url);
            }

            const remoteWindow = window.open(url, '_blank');
            remoteWindow.onload = function () {
                remoteWindow.print();
            };
        },
        print: function (url = null, target = null) {
            console.trace({ url });
            return url ? state.printRemote(url, target) : state.printLocal();
        },
    };

    return state;
}
